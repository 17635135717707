<template>
	<div class="aboutBrand-box">
		<customNav></customNav>
		<el-row :gutter="24" style="margin: 0;">
			<el-image :src="require('@/assets/image/banner-about.jpg')"></el-image>
		</el-row>
		<el-row :gutter="24" style="margin: 0;">
			<el-col :span="20" :offset="2">
				<div class="card">
					<div class="video-box">
						<video id="video" class="video-bx" loop="loop" controls="controls" muted="muted"
							autoplay="autoplay">
							<source src="https://img.vanjiax.com/jiuZiYan/video/about.mp4" type="video/mp4">
						</video>
					</div>
					<div class="des-text">
						<div class="title">合肥创农生物科技有限公司</div>
						<div class="des-content">
							<p>合肥创农生物科技有限公司成立于2018年5月，是集研发制造移动式智能植物栽培柜和温室水培的模块化设备以及提供植物工厂整厂解决方案于一体的高科技农企。</p>
							<p>公司将植物工厂技术浓缩优化，形成可以在小型独立场景下使用的智能植物栽培柜，实现全环境要素控制并获得多项国家专利，目前可提供超100种不同植物的种植技术支持，涵盖营养液配方、光配方等。
							</p>
							<p>首个产业化基地位于安徽合肥国家大学科技园，是利用LED光谱技术，采用自动化环境控制及智能化装备，在十万级净化厂房内进行植物规模化种植，主要生产高品质安全蔬菜及具有药用食用价值的花卉香料等。
							</p>
							<p>公司正以总部基地为依托，加快实现LED植物光源、生物制剂及植物工厂自动化装备的产业化，并逐步扩大在产业链上下游的投资布局，同时积极拓展境外市场，向境外输出具有自主知识产权的植物工厂装备及技术服务。
							</p>
							<p>目前公司主营移动式智能植物栽培柜及相关技术支持，针对不同用户需求，提供匹配的定制解决方案；绿色蔬菜花卉的种植与市场供应，侧重与健康、口感和功能性。</p>
						</div>
					</div>
				</div>
			</el-col>
		</el-row>

		<el-row :gutter="24" style="margin: 0;border-bottom: 1px solid #EEEEEE;">
			<el-col :span="20" :offset="2">
				<div class="box">
					<div class="title">核心竞争力</div>
					<div class="content">
						<div class="item">
							<div class="img">
								<img :src="require('@/assets/image/core-item01.png')"></img>
							</div>
							<div class="des">
								<div>不同植物的对应光配方</div>
								<div>
									使得每种植物保持<span>最佳</span>的生长效果
								</div>
							</div>
						</div>
						<div class="item">
							<div class="img">
								<img :src="require('@/assets/image/core-item02.png')"></img>
							</div>
							<div class="des">
								<div>不同植物对应的营养液配方</div>
								<div>
									保证每种作物的<span>产量</span>和<span>品质</span>
								</div>
							</div>
						</div>
						<div class="item">
							<div class="img">
								<img :src="require('@/assets/image/core-item03.png')"></img>
							</div>
							<div class="des">
								<div>环境参数的集成控制</div>
								<div>
									使得作物种植<span>周期更短</span>
								</div>
							</div>
						</div>
						<div class="item">
							<div class="img">
								<img :src="require('@/assets/image/core-item04.png')"></img>
							</div>
							<div class="des">
								<div>包括蔬菜、药材、可食用花卉</div>
								<div>
									在内的<span>100余项</span>品种的
								</div>
								<div>
									种植参数技术支持
								</div>
							</div>
						</div>
					</div>
				</div>
			</el-col>
		</el-row>

		<el-row :gutter='24' style="margin: 0;">
			<el-col :span="20" :offset='2'>
				<div class="box">
					<div class="title">企业荣誉</div>
					<div class="slide-warp">
						<!-- Using the slider component -->
						<slider ref="slider1" :options="options1">
							<!-- slideritem wrapped package with the components you need -->
							<slideritem v-for="(item,index) in honorlist" :key="index" class="slide-item">
								<img :src="item.src" class="item-img1" />
								<div class="label">{{item.label}}</div>
							</slideritem>
						</slider>
						<div class="btn">
							<img src="@/assets/image/button-left.png" @click="handlePre" />
							<img src="@/assets/image/button-right.png" @click="handleNext" />
						</div>
					</div>
				</div>
			</el-col>
		</el-row>


		<el-row :gutter='24' style="margin: 0;">
			<el-col :span="20" :offset='2'>
				<div class="box">
					<div class="title">桌面式植物栽培盒</div>
					<div class="slide-warp" style="height: 600px;">
						<!-- Using the slider component -->
						<slider ref="slider" :options="options" @slide='slide'>
							<!-- slideritem wrapped package with the components you need -->
							<template slot-scope="coverflow">
								<slideritem v-for="(item,index) in goodslist" :pageLength="goodslist.length"
									:index="index" :key="index" class="slide-item2">
									<div :class="currentPage == index ? 'item-box':'item-box-mask'">
										<img :src="item.src" class="img2" />
										<div class="mask"></div>
									</div>
								</slideritem>
							</template>
							<!-- Customizable loading -->
						</slider>
						<div class="btns">
							<img src="@/assets/image/button-left.png" @click="handlePreGood" />
							<img src="@/assets/image/button-right.png" @click="handleNextGood" />
						</div>
					</div>
				</div>
			</el-col>
		</el-row>

		<el-row :gutter="24" style="margin: 0;margin-top: 50px;">
			<el-image :src="require('@/assets/image/adv.png')"></el-image>
		</el-row>

		<el-row :gutter="24" style="margin: 0;">
			<div class="box">
				<div class="title">合作企业</div>
				<div class="logo-content">
					<img v-for="(item,index) in logolist" :key="index" :src="item.src" />
				</div>
			</div>
		</el-row>

		<el-row :gutter="24" style="margin: 0;">
			<div class="banner-box">
				<div class="label">订购我的桌面花园</div>
				<div class="val" @click="handleOrder">
					<span>去订购</span>
					<img src="../assets/image/icon-go.png">
				</div>
			</div>
			<bottom-bar></bottom-bar>
		</el-row>

	</div>
</template>

<script>
	import customNav from '@/components/custom-nav.vue'
	// import slider components
	import {
		slider,
		slideritem
	} from 'vue-concise-slider'
	import bottomBar from '@/components/bottomBar.vue';
	export default {
		name: 'aboutBrand',
		data() {
			return {
				//Slider configuration [obj]
				options1: {
					currentPage: 0,
					thresholdDistance: 100,
					thresholdTime: 300,
					loop: true,
					loopedSlides: 3,
					pagination: false,
					slidesToScroll: 1, //每次滑动项数
				},

				//Slider configuration [obj]
				options: {
					effect: 'coverflow',
					currentPage: 0,
					thresholdDistance: 100,
					thresholdTime: 300,
					deviation: 380,
					widthScalingRatio: 0.7,
					heightScalingRatio: 0.7,
					slidesToScroll: 1,
					pagination: false,
					loop: true
				},
				currentPage: 0,
				honorlist: [{
						src: require('@/assets/image/change1.png'),
						label:'计算机软件著作权登记证书'
					},
					{
						src: require('@/assets/image/change2.png'),
						label: '计算机软件著作权登记证书'
					},
					{
						src: require('@/assets/image/change3.png'),
						label: '计算机软件著作权登记证书'
					},
					{
						src: require('@/assets/image/change4.png'),
						label: '实用新型专利证书'
					},
					{
						src: require('@/assets/image/change5.png'),
						label: '实用新型专利证书'
					}
					// {
					// 	src: require('@/assets/image/honor-item04.png'),
					// 	label: '计算机软件著作权登记证书'
					// }

				],
				goodslist: [{
						src: require('@/assets/image/goods-item01.png'),
						label: '方寸间桌面花园',
						value: '奇妙蛙'
					}, {
						src: require('@/assets/image/goods-item02.png'),
						label: '方寸间桌面花园',
						value: '四季鹿'
					}, {
						src: require('@/assets/image/goods-item03.png'),
						label: '方寸间桌面花园',
						value: '萌小咪'
					},
					{
						src: require('@/assets/image/goods-item04.png'),
						label: '方寸间桌面花园',
						value: '皮卡丘'
					}
				],
				logolist: [{
					src: require('@/assets/image/logo01.png')
				}, {
					src: require('@/assets/image/logo02.png')
				}, {
					src: require('@/assets/image/logo03.png')
				}, {
					src: require('@/assets/image/logo04.png')
				}]
			}
		},
		components: {
			slider,
			slideritem,
			bottomBar,
			customNav
		},
		created() {
			window.scrollTo(0, 0)
		},
		methods: {
			handlePre() {
				this.$refs.slider1.$emit('slidePre')
			},
			handleNext() {
				this.$refs.slider1.$emit('slideNext')
			},
			handlePreGood() {
				this.$refs.slider.$emit('slidePre')
			},
			handleNextGood() {
				this.$refs.slider.$emit('slideNext')
			},
			slide(data) {
				this.currentPage = data.currentPage
			},
			handleOrder() {
				window.location.href="https://detail.tmall.com/item.htm?id=654011800008"
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
.aboutBrand-box{
	border: 1px solid balck;
	.el-image{
		width: 100%;
	}
		.card {
		margin-top: -20%;
		display: flex;
		box-shadow: -2px 10px 30px 0px rgba(151, 151, 151, 0.1);

		.video-box {
			width: 550px;
			background-color: #FFFFFF;

			.video-bx {
				width: 100%;
				height: 100%;

			}
		}

		.des-text {
			flex: 1;
			background-color: #fff;
			padding: 44px 30px 37px 40px;

			.title {
				font-size: 24px;
				font-weight: 500;
				color: #111111;
			}

			.des-content {
				margin-top: 30px;
				font-size: 16px;
				color: #666666;
				line-height: 30px;

				p {
					text-indent: 2rem;
				}
			}

			@media screen and (max-width:1500px) {
				.title {
					font-size: 20px;
				}

				.des-content {
					margin-top: 20px;
					font-size: 12px;
					line-height: 20px;
				}
			}
		}
	}

	.box {
		margin-top: 100px;
		padding-bottom: 80px;

		.title {
			font-size: 30px;
			font-weight: 500;
			color: #111111;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		@media screen and (max-width:1500px) {
			.title {
				font-size: 26px;
			}
		}

		.slide-warp {
			margin-top: 50px;
			padding: 0 100px;

			.slide-item {
				width: 33.3%;
				display: flex;
				flex-direction: column;

				.label {
					margin-top: 10px;
					font-size: 20px;
					color: #000000;
				}
			}

			.slide-item2 {
				width: 33.3%;
				display: flex;
				flex-direction: column;
				.label {
					margin-top: 10px;
					font-size: 20px;
					color: #000000;
				}
				
				.img2 {
					width: 700px;
				}
				
				@media screen and (max-width:1900px) {
					.img2 {
						width: 500px;
					}
				}
			}


		}

		@media screen and (max-width:1500px) {
			.slide-warp {
				// background-color: yellow;
				margin: 0px;

				// padding: 0;
				.slide-item {
					.item-img1 {
						width: 230px;
						height: 350px;
					}

					.label {
						font-size: 16px;
					}
				}

				.slide-item2 {
					width: 33.3%;
					height: 100px;
					display: flex;
					flex-direction: column;


					.label {
						margin-top: 10px;
						font-size: 20px;
						color: #000000;
					}
				}
			}

		}

		.btn {
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			z-index: 9;
			padding: 0 200px;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		@media screen and (max-width:1500px) {
			.btn {
				padding: 0 100px;

				img {
					width: 50px;
				}
			}
		}

		.btns {
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				width: 60px;
				height: 60px;
			}
		}

		@media screen and (max-width:1500px) {
			.btns {
				margin-top: -100px;
				img {
					width: 40px;
					height: 40px;
				}
			}
		}



		.logo-content {
			padding: 60px 260px;
			display: flex;
			align-items: center;
			justify-content: space-around;
		}

		@media screen and (max-width:1500px) {
			.logo-content {
				padding: 20px 100px;

				img {
					width: 120px;
				}
			}
		}

		.content {
			margin-top: 50px;
			background-color: #fff;
			display: flex;
			justify-content: space-between;

			.item {
				height: 200px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;

				.img {
					height: 100px;
					display: flex;
					justify-content: center;
					align-items: center;
				}



				.des {
					margin-top: 20px;
					flex: auto;
					display: flex;
					flex-direction: column;
					align-items: center;
					font-size: 16px;
					color: #666666;

					div {
						margin-bottom: 5px;

						span {
							color: #4AB17D;
							font-size: 20px;
						}
					}
				}
			}

			@media screen and (max-width:1500px) {
				.item {
					height: 100px;

					.img {
						height: 100px;

						img {
							height: 70px;
						}
					}

					.des {
						font-size: 14px;

						div {
							span {
								font-size: 16px;
							}
						}
					}
				}
			}

		}
	}

	.banner-box {
		background: #4AB17D;
		padding: 53px 0 35px 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.label {
			font-size: 24px;
			color: #FFFFFF;
		}

		.val {
			margin-top: 18px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 16px;
			color: #FFFFFF;

			img {
				margin-left: 10px;
				width: 26px;
				height: 26px;
			}
		}

		@media screen and (max-width:1500px) {
			.label {
				font-size: 18px;
			}

			.val {

				font-size: 12px;

				img {
					width: 18px;
					height: 18px;
				}
			}
		}


	}
	.item-box-mask {
		position: relative;
		z-index: 6;
	
		.mask {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-color: rgba(0, 0, 0, 0.5);
			z-index: 2;
		}
	}
	
	.item-box {
		.mask {
			display: none;
		}
	}
}

</style>
